<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="summersessionheader"/>
    <div class="container-fluid summer-session-banner noPrint">
      <div class="row ">
          <div class="summerimagecircle summerimagespan hidden-xs hidden-sm">
            <br/>
            Attention<br>Conflict<br>Professionals!<br/>
          </div>
          <span class="summertitlespan hidden-xs hidden-sm">
            <br/><br/>
            Summer Sessions <br/>for  Conflict Professionals <br/>
          </span>
      </div>
    </div>

    <div class="container-fluid quotecontainer noPrint">
      <div class="container quotetext"> 
      Join us for this <span class="largetext">unique opportunity for professional development</span> 
      <br/>in Charlottetown, Prince Edward Island, Canada 
      </div>
    </div>

    <h1 class="hidden-sm hidden-md hidden-lg" style="text-align:center;">Summer Sessions for <span class="noPrint"><br/></span> Conflict Professionals  
    <br/></h1>
    <div class="container-fluid textured" style="padding-top:50px;padding-bottom:50px;">     
      <div class="container" style="text-align:left;">
        <h1 >Summer Sessions</h1>
        <p>Join us for this unique opportunity for professional development on beautiful Prince Edward Island, Canada. 
          Summer Sessions brings together Conflict Professionals who want to delve deeply into the skills and insights 
          needed for real conflict resolution. Each summer, we offer advanced training to professionals who want to be 
          more natural and effective in their practice. Come to Prince Edward Island to learn and enjoy a vacation on our Island!</p>
      </div>
    </div>
    
    <!-- -->
    <div class="container-fluid maincontentcontainer">     
  <div class="container">

    <div class="row">
      <div class="col-xs-12 col-sm-8" v-if="events.length > 0">
        <div class="row" v-for="(event, index) in events" :key="index">
            <div class="col-xs-12 event-list-box">
              <div class="col-xs-12 col-sm-3">
                <img v-if="event.imageFilename" :src="baseURL + eventImagesPath + event.imageFilename" class=" img-responsive rounded">
                <img v-else src="../assets/images/sessions-circle.png" class=" img-responsive">
              </div>
              <div class="col-xs-12 col-sm-9">
                <h3 class="event-title" v-html="event.title" />
                <h4 style="font-size:12pt;" v-html="event.subtitle" />
                <div style="width:100%;"><div style="width:80px;margin-top:-10px; margin-bottom:-10px;"><hr/></div></div>
                <p v-html="postDescription(event.description)"></p>
                
                <a class="summer-session-list-button" @click="showDetails(event)">
                  See Session Details <span class="glyphicon glyphicon-chevron-right"></span>
                </a>
                &nbsp;
                <a class="summer-session-list-button" :href="`http://${event.externalRegistrationURL}`" target="blank" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'open'">
                  Register Now <span class="glyphicon glyphicon-chevron-right"></span>
                </a>
                &nbsp;
                <span class="summer-session-list-button opening" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'notYet'">
                  Registration Opening <b></b> <span class="glyphicon glyphicon-calendar"></span>
                </span>
                &nbsp;
                <span class="summer-session-list-button closed" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'closed'">
                  Closed for Registration <span class="glyphicon glyphicon-remove-circle"></span>
                </span>
                &nbsp;
                <span class="summer-session-list-button sold-out" v-show="postStatus(event.regOpen,event.regClose,event.seats,event.externalRegistrationURL) === 'soldOut'">
                  Sold Out <span class="glyphicon glyphicon-exclamation-sign"></span>
                </span> 
              </div>
            </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-8" v-else>There are no sessions to show at this moment. Check back soon!</div>
      <div class="col-xs-12 col-sm-4">
      <h3 style="text-align:center;">Learn What PEI Can Offer You!</h3>
<!-- Slideshow container -->
<div class="slideshow-container">
    <Gallaria 
      :value="tourismImages"
      :circular="true"
      :autoPlay="true"
      :transitionInterval="9000"
      :showIndicators="true"
      :showThumbnails="false" 
    >
      <template #item="slotProps">
        <div>
        <img :src="slotProps.item.imagePath" style="width:100%">
        <div class="gallery-title">
          {{ postDescription(slotProps.item.title)}}
        </div>
        <div class="gallery-description">
          {{ postDescription(slotProps.item.description) }}
        </div>
        </div>
      </template>
    </Gallaria>
  
</div>
<a style="color: white;" class="summerlinks col-xs-12 summerlinkspan" target="_blank" href="https://www.experiencepei.ca/">Things To Do on PEI</a>
<a style="color: white;" target="_blank" class="summerlinks2 col-xs-12 summerlinkspan"  href="http://www.tourismpei.com/pei-accommodations">Accommodations</a>
<p style="padding-top:10px;text-align:center;">Images courtesy of: <a target="_blank" href="http://www.tourismpei.com/">Tourism PEI</a> and <a target="_blank" href="http://www.johnsylvester.com/index.php">John Sylvester</a></p>
<div class="row">
<div class="col-xs-12" style="margin-top: 20px;">
<a href="https://www.facebook.com/jacintagallanttraining/" target="_blank" style="font-size: 17pt; text-shadow: 1px 1px 1px #cbc2b4;line-height: 1.1; color: #475993;">
<img title="Visit us on Facebook!" style="width: 50px;margin-right:10px;float: left;" src="../assets/images/facebook.png"> Check out other training opportunities on Facebook!</a>
</div>
</div>
</div><!--right box-->
</div><!-- row -->
<div class="col-xs-12 noPrint">
<div style="font-size:20px; color:#708185;text-align:center;"><br/><br/>Thank you for your interest in Summer Sessions!<br>We look forward to receiving your registration.</div>
</div>
</div>
</div>
  <event-detail
    v-if="showEventDetail"
    :event="event"
    :trainer="trainer"
  />
    <Footer/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'

// Helpers
import Dates from '../methods/Date.js'
import moment from 'moment'

// API Services
import EventServices from '../services/EventServices.js'

// Primevue
import Gallaria from 'primevue/galleria'

export default {
  name: 'Summer Sessions',
  components: {
    Header,
    Footer,
    Gallaria
  },
  setup() {
    const router = useRouter()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const baseURL = process.env.VUE_APP_API_SERVER
    const eventImagesPath = process.env.VUE_APP_EVENT_IMAGES
    const events = ref([])
    const event = ref()
    const trainer = ref()
    var showEventDetail = false 

    const tourismImages = [
      {imagePath:'../images/street.jpg',title: '<b>Downtown Charlottetown</b><br/>',description:'Enjoy walking around Historic Charlottetown, full of galleries, cafes and pubs.'},
      {imagePath:'../images/kayak.jpg',title: '<b>Kayaking</b><br/>',description:'Outdoor adventures in the Atlantic Ocean or our beautiful rivers!'},
      {imagePath:'../images/meal.jpg',title: '<b>Fresh Seafood</b><br/>',description:'Delicious, fresh food prepared by chefs who know how to treat it!'},
      {imagePath:'../images/beach.jpg',title: '<b>Clam Digging</b><br/>',description:'Dig your own dinner!'},
      {imagePath:'../images/bridge.jpg',title: '<b>Sights</b><br/>',description:'See the beautiful sights on Prince Edward Island.'}
    ]

    onMounted(() => {
      EventServices.getAllSummerSessions()
      .then((response) => {
        events.value = response.data
      })
    })

    const postDate = (value) => {
      return Dates.longDate(value)
    }

    const postDescription = (value) => {
      let stripped = value.replace(/<\/?[^>]+>/ig, " ")
      stripped = stripped.replace('&nbsp;', " ")
      return stripped.substring(0,250) + "..."
    }

    const showDetails = (event) => {
      router.push({ name:"Summer Session Detail", query:{ e: event.id }})
    }

    const postStatus = (regOpen,regClose,seats,externalRegistrationURL) => {
      if(moment.utc(regOpen).isAfter(moment.utc(Date.now()))) {
        return "notYet"
      }
      if(regClose && moment.utc(regClose).isSameOrBefore(moment.utc(Date.now()))) {
        return "closed"
      }
      if(!externalRegistrationURL && seats === 0) {
        return "soldOut"
      }
      if(!externalRegistrationURL) {
        return "Registration not Available"
      }
      return "open"
    }

    return {
      environment,
      event,
      trainer,
      events,
      showEventDetail,
      baseURL,
      postDate,
      postDescription,
      postStatus,
      showDetails,
      eventImagesPath,
      tourismImages
    }
  }
}
</script>

<style lang="scss">
  .gallery-title {
    font-size: 1.50em;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
  }
  .gallery-description {
    font-size: 1.45em;
    text-align: left;
    padding-left: 10px;
  }
  .rounded {
    border-radius: 50%;
    overflow: hidden;
  }
</style>
